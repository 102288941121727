<template>
  <v-app>
    <AHeader />
    <v-container fluid class="text-center">
      <v-layout row wrap>
        <v-flex xs12>
          <v-data-table
            :headers="headers"
            :items="jobs"
            :search="search"
            :items-per-page="10"
            item-key="id"
            sort-by="id"
            :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'mdi-arrow-collapse-left',
              lastIcon: 'mdi-arrow-collapse-right',
              prevIcon: 'mdi-minus',
              nextIcon: 'mdi-plus',
            }"
          >
            <template v-slot:top>
              <v-toolbar flat color="white">
                <v-toolbar-title>工作管理 / Job Management</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  label="搜尋 / Search"
                  single-line
                  hide-details
                ></v-text-field>
                <v-dialog v-model="dialog" max-width="500px">
                  <v-card>
                    <v-card-title>
                      <span class="headline"
                        >更改預約時間 / Modify Schedule</span
                      >
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field
                              v-model="editedItem.scheduled"
                              label="約定 / Scheduled"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="orange lighten-4" tile @click="close">
                        取消 / Cancel
                      </v-btn>
                      <!-- <v-btn
                          color="cyan darken-1"
                          tile
                          @click="deleteItem(item)"
                        >
                          刪除 / Delete
                        </v-btn> -->
                      <v-btn color="orange lighten-4" tile @click="save">
                        保存 / Save
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:item.status="{ item }">
              <div v-if="item.status == 3">
                <v-btn
                  color="orange lighten-4"
                  tile
                  small
                  @click="getScanList(item)"
                >
                  預覽 / Browse
                </v-btn>
              </div>
              <div v-else-if="item.status == 2">掃描中 / Processing</div>
              <div v-else>
                <v-btn
                  color="orange lighten-4"
                  tile
                  small
                  @click="editItem(item)"
                >
                  更改 / Edit
                </v-btn>
              </div>
            </template>
            <template v-slot:no-data>
              <v-btn color="orange lighten-4" tile small @click="getJobList">
                重設 / Reload
              </v-btn>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
    </v-container>
    <AFooter />
  </v-app>
</template>

<script>
import AHeader from "@/components/AHeader.vue";
import AFooter from "@/components/AFooter.vue";
import { mapGetters } from "vuex";
import qs from "qs";

export default {
  data() {
    return {
      dialog: false,
      search: "",
      headers: [
        { text: "編號 / ID", align: "center", value: "id" },
        { text: "姓 / Last Name", align: "center", value: "last_name" },
        { text: "名 / First Name", align: "center", value: "first_name" },
        { text: "預定時間 / Scheduled", align: "center", value: "scheduled" },
        { text: "狀況 / Status", align: "center", value: "status" },
      ],
      jobs: [],
      editedIndex: -1,
      editedItem: {
        id: "",
        scheduled: "",
      },
    };
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  created() {
    this.getJobList();
  },
  computed: {
    ...mapGetters({
      StudioInfo: "studioinfo",
    }),
  },
  methods: {
    async getJobList() {
      await this.axios
        .get(this.serverurl + "/getJobs/" + this.StudioInfo.user.id, {
          headers: {
            Authorization: "Bearer " + this.StudioInfo.token,
          },
        })
        .then((response) => {
          //console.log(response.data);
          this.jobs = response.data;
        })
        .catch((error) => {
          this.errors = error;
        });
    },
    async getScanList(client) {
      if (client.status == 3) {
        if (client.id != this.$store.getters.clientinfo.id) {
          this.$store.dispatch("set_ClientInfo", client);
          await this.axios
            .get(this.serverurl + "/getScans/" + client.id, {
              headers: {
                Authorization: "Bearer " + this.StudioInfo.token,
              },
            })
            .then((response) => {
              this.$store.dispatch("set_Scans", response.data);
            })
            .catch((error) => {
              this.errors = error;
            });
        }
        this.$router.push("scans");
      }
    },
    async updateJob() {
      await this.axios
        .post(
          this.serverurl + "/updateJob",
          qs.stringify({
            id: this.editedItem.id,
            scheduled: this.editedItem.scheduled,
          }),
          {
            headers: {
              Authorization: "Bearer " + this.StudioInfo.token,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          this.getJobList();
          this.clear();
        })
        .catch((error) => {
          this.errors = error;
        });
    },
    clear() {
      this.$refs.form.reset();
    },

    editItem(item) {
      this.editedIndex = this.jobs.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    // deleteItem(item) {
    //   const index = this.jobs.indexOf(item);
    //   confirm("你確定嗎？ / Are you sure?") && this.clients.splice(index, 1);
    //   this.deleteJob(item.id);
    // },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      if (this.editedIndex > -1) {
        this.updateJob();
        Object.assign(this.jobs[this.editedIndex], this.editedItem);
      }
      this.close();
    },
  },
  components: {
    AHeader,
    AFooter,
  },
};
</script>
