<template>
  <v-data-table
    :headers="r1headers"
    :items="jobs"
    :search="search"
    :items-per-page="10"
    item-key="id"
    sort-by="id"
    :footer-props="{
      showFirstLastPage: true,
      firstIcon: 'mdi-arrow-collapse-left',
      lastIcon: 'mdi-arrow-collapse-right',
      prevIcon: 'mdi-minus',
      nextIcon: 'mdi-plus',
    }"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-menu
          ref="r1menu"
          v-model="r1menu"
          :close-on-content-click="false"
          :return-value.sync="r1date"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="r1date"
              label="挑選月份 / Pick a month"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="r1date" type="month" no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="r1menu = false">
              取消 / Cancel
            </v-btn>
            <v-btn text color="primary" @click="getDoneScans(r1date)">
              去 / OK
            </v-btn>
          </v-date-picker>
        </v-menu>

        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          label="搜尋 / Search"
          single-line
          hide-details
        ></v-text-field>
      </v-toolbar>
    </template>
    <!-- <template v-slot:item.status="{ item }">
      <div>{{ item.status }}</div>
    </template> -->
  </v-data-table>
</template>

<script>
export default {
  data: () => ({
    search: "",
    r1date: "",
    r1menu: false,
    r1headers: [
      { text: "編號 / ID", align: "center", value: "id" },
      { text: "姓 / Last Name", align: "center", value: "last_name" },
      { text: "名 / First Name", align: "center", value: "first_name" },
      { text: "完成於 / Completed by", align: "center", value: "scheduled" },
      //      { text: "狀況 / Status", align: "center", value: "status" },
    ],
    jobs: [],
  }),
  created() {
    this.getDoneScans(this.$store.getters.r1month);
  },
  methods: {
    async getDoneScans(rdate) {
      await this.axios
        .get(
          this.serverurl +
            "/getScanReport/" +
            this.StudioInfo.user.id +
            "/" +
            this.Rdate,
          {
            headers: {
              Authorization: "Bearer " + this.StudioInfo.token,
            },
          }
        )
        .then((response) => {
          this.jobs = response.data;
          this.r1menu = false;
          this.$store.dispatch("set_R1Month", rdate);
          this.$refs.r1menu.save(rdate);
        })
        .catch((error) => {
          this.errors = error;
        });
    },
  },
};
</script>