<template>
  <v-app>
    <AHeader />
    <v-container fluid class="text-center">
      <h3>{{ dataMsg }}</h3>
      <v-layout justify-center>
        <v-flex xs12 sm10 md8 lg6>
          <v-form ref="form">
            <v-card-text>
              <v-row>
                <v-col>
                  <v-autocomplete
                    v-model="selectedClient"
                    label="客戶名稱 / Client"
                    :items="clients"
                    :item-text="clients => clients.last_name + ', ' + clients.first_name"
                    item-value="id"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-select
                    v-model="selectedBooth"
                    :items="booths"
                    item-text="location"
                    item-value="id"
                    label="埸地 / Booth"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-dialog
                    ref="dialog1"
                    v-model="modal1"
                    :return-value.sync="sch_date"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="sch_date"
                        label="日期 / date"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="sch_date" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn
                        tile
                        color="orange lighten-4"
                        @click="modal1 = false"
                      >
                        取消 / Cancel
                      </v-btn>
                      <v-btn
                        tile
                        color="orange lighten-4"
                        @click="$refs.dialog1.save(sch_date)"
                      >
                        去 / OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col>
                  <v-dialog
                    ref="dialog2"
                    v-model="modal2"
                    :return-value.sync="sch_time"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="sch_time"
                        label="時間 / Time"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker v-if="modal2" v-model="sch_time">
                      <v-spacer></v-spacer>
                      <v-btn
                        tile
                        color="orange lighten-4"
                        @click="modal2 = false"
                      >
                        取消 / Cancel
                      </v-btn>
                      <v-btn
                        tile
                        color="orange lighten-4"
                        @click="$refs.dialog2.save(sch_time)"
                      >
                        去 / OK
                      </v-btn>
                    </v-time-picker>
                  </v-dialog>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn tile x-large color="orange lighten-4" to="/mainmenu">
                取消 / Cancel
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn tile x-large color="orange lighten-4" @click="submit">
                提交 / Submit
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-flex>
      </v-layout>
    </v-container>
    <AFooter />
  </v-app>
</template>

<script>
import AHeader from "@/components/AHeader.vue";
import AFooter from "@/components/AFooter.vue";
import { mapGetters } from "vuex";
import qs from "qs";

export default {
  data() {
    return {
      dataMsg: "",
      date: new Date().toISOString().substr(0, 10),
      modal1: false,
      modal2: false,
      clients: [],
      booths: [],
      selectedClient: "",
      selectedBooth: "",
      sch_date: "",
      sch_time: "",
    };
  },
  created() {
    this.getClientNames();
    this.getBooths();
  },
  computed: {
    ...mapGetters({
      StudioInfo: "studioinfo",
    }),
  },
  methods: {
    async getClientNames() {
      await this.axios
        .get(this.serverurl + "/getClients/" + this.StudioInfo.user.id, {
          headers: {
            Authorization: "Bearer " + this.StudioInfo.token,
          },
        })
        .then((response) => {
          this.clients = response.data;
        })
        .catch((error) => {
          this.errors = error;
        });
    },
    async getBooths() {
      await this.axios
        .get(this.serverurl + "/getBooths/" + this.StudioInfo.user.id, {
          headers: {
            Authorization: "Bearer " + this.StudioInfo.token,
          },
        })
        .then((response) => {
          this.booths = response.data;
        })
        .catch((error) => {
          this.errors = error;
        });
    },
    async submit() {
      await this.axios
        .post(
          this.serverurl + "/newJob",
          qs.stringify({
            studio_id: this.StudioInfo.user.id,
            booth_id: this.selectedBooth,
            client_id: this.selectedClient,
            scheduled: this.sch_date + " " + this.sch_time,
          }),
          {
            headers: {
              Authorization: "Bearer " + this.StudioInfo.token,
            },
          }
        )
        .then((response) => {
          this.dataMsg = response.data;
          this.$router.push("jobs");
        })
        .catch((error) => {
          this.dataMsg = error.data;
        });
    },
    clear() {
      this.$refs.form.reset();
    },
  },
  components: {
    AHeader,
    AFooter,
  },
};
</script>
