<template>
  <div class="hello">
    <ul class="cb-slideshow">
      <li>
        <span>Image 01</span>
        <div>
          <h3>se·ren·i·ty</h3>
        </div>
      </li>
      <li>
        <span>Image 02</span>
        <div>
          <h3>com·po·sure</h3>
        </div>
      </li>
      <li>
        <span>Image 03</span>
        <div>
          <h3>e·qua·nim·i·ty</h3>
        </div>
      </li>
      <li>
        <span>Image 04</span>
        <div>
          <h3>bal·ance</h3>
        </div>
      </li>
      <li>
        <span>Image 05</span>
        <div>
          <h3>qui·e·tude</h3>
        </div>
      </li>
      <li>
        <span>Image 06</span>
        <div>
          <h3>re·lax·a·tion</h3>
        </div>
      </li>
    </ul>

    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="600px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn text x-large v-bind="attrs" v-on="on">
            <v-img
              :src="require('../assets/logo.svg')"
              class="my-3"
              contain
              height="180"
            />
          </v-btn>
        </template>
        <v-alert :color="mtype" icon="warning_amber" v-if="mtype">
          {{ msg }}
        </v-alert>

        <v-card class="rounded-card">
          <v-card-title>
            <span class="headline py-5">登入 / Sign In</span>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="email"
                  label="電郵 / Email"
                  :rules="emailRules"
                  type="email"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-on:keyup.enter="submit"
                  v-model="pass"
                  label="密碼 / Password"
                  type="password"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn tile x-large color="cyan darken-1" @click="dialog = false">
              取消 / Cancel
            </v-btn>
            <v-btn tile x-large color="cyan darken-1" @click="submit">
              提交 / Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import qs from "qs";

export default {
  data() {
    return {
      mtype: null,
      msg: "",
      dialog: false,
      email: "",
      pass: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail must be valid",
      ],
    };
  },
  methods: {
    async submit() {
      await this.axios
        .post(
          this.serverurl + "/login",
          qs.stringify({
            email: this.email,
            pass: this.pass,
          })
        )
        .then((response) => {
          //console.log(response.data);
          if (response.data.token) {
            this.$store.dispatch("set_StudioInfo", response.data);
            this.$store.dispatch(
              "set_R1Month",
              new Date().toISOString().substr(0, 7)
            );
            this.$store.dispatch(
              "set_R2Month",
              new Date().toISOString().substr(0, 7)
            );
            this.$router.push("mainmenu");
          } else {
            this.msg = response.data;
          }
        })
        .catch((error) => {
          this.msg = error;
          this.mtype = "warning";
        });
    },
  },
  components: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import "/static/css/style2.css";
ul {
  list-style-type: none;
}
.rounded-card {
  border-radius: 2px;
}
</style>
