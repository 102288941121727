var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('AHeader'),_c('v-container',{staticClass:"text-center",attrs:{"fluid":""}},[_c('v-layout',{attrs:{"row":"","justify-center":""}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.dialog_order),callback:function ($$v) {_vm.dialog_order=$$v},expression:"dialog_order"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("訂單詳情 / Order Detail")])]),_c('v-card-text'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"orange lighten-4","tile":""},on:{"click":function($event){_vm.dialog_order = false}}},[_vm._v(" 關閉 / Close ")]),_c('v-btn',{attrs:{"color":"orange lighten-4","tile":""},on:{"click":function($event){return _vm.submit(0)}}},[_vm._v(" 保存 / Save ")]),_c('v-btn',{attrs:{"color":"orange lighten-4","tile":""},on:{"click":function($event){return _vm.submit(1)}}},[_vm._v(" 下單 / Order ")])],1)],1)],1)],1),_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.orders,"search":_vm.search,"items-per-page":10,"item-key":"id","sort-by":"id","footer-props":{
            showFirstLastPage: true,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
            prevIcon: 'mdi-minus',
            nextIcon: 'mdi-plus',
          }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("訂單管理 / Order Management")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-text-field',{attrs:{"label":"搜尋 / Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"item.status",fn:function(ref){
          var item = ref.item;
return [(item.status == 0)?_c('div',[_c('v-btn',{attrs:{"color":"orange lighten-4","tile":"","small":""},on:{"click":function($event){return _vm.editOrder(item)}}},[_vm._v(" 保存 / Saved ")])],1):(item.status == 1)?_c('div',[_vm._v("打印中 / Processing")]):(item.status == 2)?_c('div',[_vm._v("復審中 / Reviewing")]):_vm._e()]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"orange lighten-4","tile":"","small":""},on:{"click":_vm.getOrderList}},[_vm._v(" 重設 / Reload ")])]},proxy:true}])})],1)],1)],1),_c('AFooter')],1)}
var staticRenderFns = []

export { render, staticRenderFns }