<template>
  <v-app>
    <AHeader />
    <v-container fluid class="text-center">
      <v-layout row justify-center>
        <v-dialog v-model="dialog_order" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="headline">訂單詳情 / Order Detail</span>
            </v-card-title>
            <v-card-text>
              <!-- <v-layout wrap>
                  <v-flex xs12>{{ currentScan.id }}</v-flex>
                  <v-flex xs12>
                    <v-select
                      :items="['1:10', '1:9', '1:8', '1:7', '1:6', '1:5']"
                      v-model="currentScan.size"
                      label="尺寸 / Size"
                      required
                    ></v-select>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      v-model="currentScan.qty"
                      label="數量 / Quantity"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      v-model="currentScan.remark"
                      label="備註 / Remark"
                    ></v-text-field>
                  </v-flex>
                </v-layout> -->
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="orange lighten-4"
                tile
                @click="dialog_order = false"
              >
                關閉 / Close
              </v-btn>
              <v-btn color="orange lighten-4" tile @click="submit(0)">
                保存 / Save
              </v-btn>
              <v-btn color="orange lighten-4" tile @click="submit(1)">
                下單 / Order
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
      <v-layout row wrap>
        <v-flex xs12>
          <v-data-table
            :headers="headers"
            :items="orders"
            :search="search"
            :items-per-page="10"
            item-key="id"
            sort-by="id"
            :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'mdi-arrow-collapse-left',
              lastIcon: 'mdi-arrow-collapse-right',
              prevIcon: 'mdi-minus',
              nextIcon: 'mdi-plus',
            }"
          >
            <template v-slot:top>
              <v-toolbar flat color="white">
                <v-toolbar-title>訂單管理 / Order Management</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  label="搜尋 / Search"
                  single-line
                  hide-details
                ></v-text-field>
              </v-toolbar>
            </template>
            <template v-slot:item.status="{ item }">
              <div v-if="item.status == 0">
                <v-btn
                  color="orange lighten-4"
                  tile
                  small
                  @click="editOrder(item)"
                >
                  保存 / Saved
                </v-btn>
              </div>
              <div v-else-if="item.status == 1">打印中 / Processing</div>
              <div v-else-if="item.status == 2">復審中 / Reviewing</div>
            </template>
            <template v-slot:no-data>
              <v-btn color="orange lighten-4" tile small @click="getOrderList">
                重設 / Reload
              </v-btn>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
    </v-container>
    <AFooter />
  </v-app>
</template>

<script>
import AHeader from "@/components/AHeader.vue";
import AFooter from "@/components/AFooter.vue";
import { mapGetters } from "vuex";
import qs from "qs";

export default {
  data() {
    return {
      search: "",
      dialog_order: false,
      headers: [
        { text: "訂單編號 / Order ID", align: "center", value: "id" },
        { text: "客戶編號 / Client ID", align: "center", value: "client_id" },
        { text: "掃描編號 / Scan ID", align: "center", value: "scan_id" },
        { text: "尺寸 / Size", align: "center", value: "size" },
        { text: "數量 / Quantity", align: "center", value: "quantity" },
        { text: "備註 / Remark", value: "remark" },
        { text: "下單日期 / Order Date", align: "center", value: "order_date" },
        { text: "狀況 / Status", align: "center", value: "status" },
      ],
      orders: [],
    };
  },
  created() {
    this.getOrderList();
  },
  computed: {
    ...mapGetters({
      StudioInfo: "studioinfo",
      ClientInfo: "clientinfo",
    }),
  },
  methods: {
    editOrder(item) {
      this.dialog_order = true;
      console.log(item);
    },
    async submit(s) {
      await this.axios
        .post(
          this.serverurl + "/newOrder",
          qs.stringify({
            studio_id: this.StudioInfo.user.id,
            client_id: this.ClientInfo.id,
            items: this.PrintItems,
            status: s,
          }),
          {
            headers: {
              Authorization: "Bearer " + this.StudioInfo.token,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          this.$store.dispatch("bigReset");
          this.dialog_order = false;
        })
        .catch((error) => {
          this.errors = error;
        });
    },
    async getOrderList() {
      await this.axios
        .get(this.serverurl + "/getOrders/" + this.StudioInfo.user.id, {
          headers: {
            Authorization: "Bearer " + this.StudioInfo.token,
          },
        })
        .then((response) => {
          //console.log(response.data);
          this.orders = response.data;
        })
        .catch((error) => {
          this.errors = error;
        });
    },
  },
  components: {
    AHeader,
    AFooter,
  },
};
</script>
