var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('AHeader'),_c('v-container',{staticClass:"text-center",attrs:{"fluid":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.jobs,"search":_vm.search,"items-per-page":10,"item-key":"id","sort-by":"id","footer-props":{
            showFirstLastPage: true,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
            prevIcon: 'mdi-minus',
            nextIcon: 'mdi-plus',
          }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("工作管理 / Job Management")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-text-field',{attrs:{"label":"搜尋 / Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("更改預約時間 / Modify Schedule")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"約定 / Scheduled"},model:{value:(_vm.editedItem.scheduled),callback:function ($$v) {_vm.$set(_vm.editedItem, "scheduled", $$v)},expression:"editedItem.scheduled"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"orange lighten-4","tile":""},on:{"click":_vm.close}},[_vm._v(" 取消 / Cancel ")]),_c('v-btn',{attrs:{"color":"orange lighten-4","tile":""},on:{"click":_vm.save}},[_vm._v(" 保存 / Save ")])],1)],1)],1)],1)]},proxy:true},{key:"item.status",fn:function(ref){
          var item = ref.item;
return [(item.status == 3)?_c('div',[_c('v-btn',{attrs:{"color":"orange lighten-4","tile":"","small":""},on:{"click":function($event){return _vm.getScanList(item)}}},[_vm._v(" 預覽 / Browse ")])],1):(item.status == 2)?_c('div',[_vm._v("掃描中 / Processing")]):_c('div',[_c('v-btn',{attrs:{"color":"orange lighten-4","tile":"","small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" 更改 / Edit ")])],1)]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"orange lighten-4","tile":"","small":""},on:{"click":_vm.getJobList}},[_vm._v(" 重設 / Reload ")])]},proxy:true}])})],1)],1)],1),_c('AFooter')],1)}
var staticRenderFns = []

export { render, staticRenderFns }