import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from "../views/Home";
import ChgPassword from "../views/ChangePassword";
import MainMenu from "../views/MainMenu";
import Scheduler from "../views/Scheduler";
import Jobs from "../views/Jobs";
import Scans from "../views/Scans";
import Preview from "../views/Preview";
import OrderList from "../views/OrderList";
import ConfirmOrder from "../views/ConfirmOrder";
import Clients from "../views/Clients";
import Reports from "../views/Reports";
import Settings from "../views/Settings";

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/changepassword",
    name: "ChgPassword",
    component: ChgPassword
  },
  {
    path: "/mainmenu",
    name: "MainMenu",
    component: MainMenu
  },
  {
    path: "/scheduler",
    name: "Scheduler",
    component: Scheduler
  },
  {
    path: "/jobs",
    name: "Jobs",
    component: Jobs
  },
  {
    path: "/scans",
    name: "Scans",
    component: Scans
  },
  {
    path: "/confirmorder",
    name: "ConfirmOrder",
    component: ConfirmOrder
  },
  {
    path: "/orderlist",
    name: "OrderList",
    component: OrderList
  },
  {
    path: "/preview",
    name: "Preview",
    component: Preview
  },
  {
    path: "/clients",
    name: "Clients",
    component: Clients
  },
  {
    path: "/reports",
    name: "Reports",
    component: Reports
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
