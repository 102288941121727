import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    studioInfo: [],
    clientInfo: [],
    scans: [],
    printOrder: [],
    previewScan: "",
    r1Month: "",
    r2Month: "",
  },
  mutations: {
    SET_STUDIOINFO: (state, payload) => {
      state.studioInfo = payload;
    },
    SET_PSCAN: (state, payload) => {
      state.previewScan = payload;
    },
    SET_CLIENTINFO: (state, payload) => {
      state.clientInfo = payload;
    },
    SET_SCANS: (state, payload) => {
      state.scans = payload;
    },
    SET_R1MONTH: (state, payload) => {
      state.r1Month = payload;
    },
    SET_R2MONTH: (state, payload) => {
      state.r2Month = payload;
    },
    ADD_PRINT: (state, payload) => {
      let newOrder = {
        scanid: payload.id,
        size: payload.size,
        qty: payload.qty,
        remark: payload.remark
      };
      state.printOrder.unshift(newOrder);
    },
    DELETE_PRINT: (state, payload) => state.printOrder.splice(state.printOrder.indexOf(payload), 1),
    BIG_RESET: state => {
      state.clientInfo = [];
      state.scans = [];
      state.printOrder = [];
      state.previewScan = "";
    }
  },
  actions: {
    set_StudioInfo: (context, payload) =>
      context.commit("SET_STUDIOINFO", payload),
    set_Pscan: (context, payload) =>
      context.commit("SET_PSCAN", payload),
    set_ClientInfo: (context, payload) =>
      context.commit("SET_CLIENTINFO", payload),
    set_Scans: (context, payload) =>
      context.commit("SET_SCANS", payload),
    set_R1Month: (context, payload) =>
      context.commit("SET_R1MONTH", payload),
    set_R2Month: (context, payload) =>
      context.commit("SET_R2MONTH", payload),
    addPrint: (context, payload) => {
      context.commit("ADD_PRINT", payload);
    },
    deletePrint: (context, payload) => {
      context.commit("DELETE_PRINT", payload);
    },
    bigReset: (context) => {
      context.commit("BIG_RESET");
    }
  },
  getters: {
    previewscan: state => {
      return state.previewScan;
    },
    printitems: state => {
      return state.printOrder;
    },
    printcount: state => {
      return state.printOrder.length;
    },
    scanlist: state => {
      return state.scans;
    },
    studioinfo: state => {
      return state.studioInfo;
    },
    clientinfo: state => {
      return state.clientInfo;
    },
    r1month: state => {
      return state.r1Month;
    },
    r2month: state => {
      return state.r2Month;
    }
  },
  modules: {
  }
})
