var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('AHeader'),_c('v-container',{staticClass:"text-center",attrs:{"fluid":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.clients,"search":_vm.search,"items-per-page":10,"item-key":"id","sort-by":"id","footer-props":{
            showFirstLastPage: true,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
            prevIcon: 'mdi-minus',
            nextIcon: 'mdi-plus',
          }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("客戶管理 / Client Management")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-text-field',{attrs:{"label":"搜尋 / Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"tile":"","color":"orange lighten-4"}},'v-btn',attrs,false),on),[_vm._v(" 新增客戶 / New Client ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"姓 / Last Name"},model:{value:(_vm.editedItem.last_name),callback:function ($$v) {_vm.$set(_vm.editedItem, "last_name", $$v)},expression:"editedItem.last_name"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"名 / First Name"},model:{value:(_vm.editedItem.first_name),callback:function ($$v) {_vm.$set(_vm.editedItem, "first_name", $$v)},expression:"editedItem.first_name"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"電話 / Mobile"},model:{value:(_vm.editedItem.mobile),callback:function ($$v) {_vm.$set(_vm.editedItem, "mobile", $$v)},expression:"editedItem.mobile"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"電郵 / Email"},model:{value:(_vm.editedItem.email),callback:function ($$v) {_vm.$set(_vm.editedItem, "email", $$v)},expression:"editedItem.email"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"身高 / Height"},model:{value:(_vm.editedItem.height),callback:function ($$v) {_vm.$set(_vm.editedItem, "height", $$v)},expression:"editedItem.height"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"orange lighten-4","tile":""},on:{"click":_vm.close}},[_vm._v(" 取消 / Cancel ")]),_c('v-btn',{attrs:{"color":"orange lighten-4","tile":""},on:{"click":_vm.save}},[_vm._v(" 保存 / Save ")])],1)],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{attrs:{"color":"orange lighten-4","tile":"","small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" 更改 / Edit ")])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"orange lighten-4","tile":"","small":""},on:{"click":_vm.getClientList}},[_vm._v(" 重設 / Reload ")])]},proxy:true}])})],1)],1)],1),_c('AFooter')],1)}
var staticRenderFns = []

export { render, staticRenderFns }