<template>
  <v-app>
    <AHeader />
    <v-container grid-list-md class="text-center">
      <v-layout row wrap>
        <v-flex xs12>
          <v-btn tile x-large color="orange lighten-4" to="scans">
            反回 / Back
          </v-btn>
          <v-btn tile x-large color="orange lighten-4" @click.stop="openDialog">
            購買 / Buy
          </v-btn>
        </v-flex>
        <v-flex xs12 text-xs-center>
          <h3>{{ ScanID }}</h3>
          <model-gltf
            backgroundColor="rgb(222,233,244)"
            :src="'/static/models/gltf/' + ScanID + '.glb'"
          ></model-gltf>
        </v-flex>
      </v-layout>
    </v-container>
    <v-layout row justify-center>
      <v-dialog v-model="dialog_order" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">訂單詳情 / Order Detail</span>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12>{{ currentScan.id }}</v-flex>
                <v-flex xs12>
                  <v-select
                    :items="['1:10', '1:9', '1:8', '1:7', '1:6', '1:5']"
                    v-model="currentScan.size"
                    label="尺寸 / Size"
                    required
                  ></v-select>
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    v-model="currentScan.qty"
                    label="數量 / Quantity"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    v-model="currentScan.remark"
                    label="備註 / Remark"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="orange lighten-4" tile @click="dialog_order = false">
              關閉 / Close
            </v-btn>
            <v-btn color="orange lighten-4" tile @click="addtoPrint">
              加單 / Add
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <AFooter />
  </v-app>
</template>

<script>
import AHeader from "@/components/AHeader.vue";
import AFooter from "@/components/AFooter.vue";
import { mapGetters } from "vuex";
import { ModelGltf } from "vue-3d-model";

export default {
  data() {
    return {
      dialog_order: false,
      currentScan: {
        id: "",
        size: "",
        qty: "",
        remark: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      ScanID: "previewscan",
    }),
  },
  methods: {
    openDialog() {
      this.currentScan.id = this.ScanID;
      this.dialog_order = true;
    },
    addtoPrint() {
      this.$store.dispatch("addPrint", this.currentScan);
      this.dialog_order = false;
      this.$router.push("scans");
    },
  },
  components: {
    ModelGltf,
    AHeader,
    AFooter,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
