<template>
  <v-data-table
    :headers="r2headers"
    :items="orders"
    :search="search"
    :items-per-page="10"
    item-key="id"
    sort-by="id"
    :footer-props="{
      showFirstLastPage: true,
      firstIcon: 'mdi-arrow-collapse-left',
      lastIcon: 'mdi-arrow-collapse-right',
      prevIcon: 'mdi-minus',
      nextIcon: 'mdi-plus',
    }"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-menu
          ref="r2menu"
          v-model="r2menu"
          :close-on-content-click="false"
          :return-value.sync="r2date"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="r2date"
              label="挑選月份 / Pick a month"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="r2date" type="month" no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="r2menu = false">
              取消 / Cancel
            </v-btn>
            <v-btn text color="primary" @click="getDoneOrders(r2date)">
              去 / OK
            </v-btn>
          </v-date-picker>
        </v-menu>

        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          label="搜尋 / Search"
          single-line
          hide-details
        ></v-text-field>
      </v-toolbar>
    </template>
    <template v-slot:item.status="{ item }">
      <div v-if="item.status == 1">打印中 / Processing</div>
      <div v-else-if="item.status == 2">完成 / Completed</div>
    </template>
  </v-data-table>
</template>

<script>
export default {
  data: () => ({
    search: "",
    r2date: "",
    r2menu: false,
    r2headers: [
      { text: "訂單編號 / Order ID", align: "center", value: "id" },
      { text: "客戶編號 / Client ID", align: "center", value: "client_id" },
      { text: "掃描編號 / Scan ID", align: "center", value: "scan_id" },
      { text: "尺寸 / Size", align: "center", value: "size" },
      { text: "數量 / Quantity", align: "center", value: "quantity" },
      { text: "備註 / Remark", value: "remark" },
      { text: "下單日期 / Order Date", align: "center", value: "order_date" },
      { text: "狀況 / Status", align: "center", value: "status" },
    ],
    orders: [],
  }),
  created() {
    this.getDoneOrders(this.$store.getters.r2month);
  },
  methods: {
    async getDoneOrders(rdate) {
      await this.axios
        .get(
          this.serverurl +
            "/getOrderReport/" +
            this.StudioInfo.user.id +
            "/" +
            this.Rdate,
          {
            headers: {
              Authorization: "Bearer " + this.StudioInfo.token,
            },
          }
        )
        .then((response) => {
          this.orders = response.data;
          this.r2menu = false;
          this.$store.dispatch("set_R2Month", rdate);
          this.$refs.r2menu.save(rdate);
        })
        .catch((error) => {
          this.errors = error;
        });
    },
  },
};
</script>