<template>
  <v-app>
    <AHeader />
    <v-container fluid class="text-center">
      <v-layout row wrap>
        <v-flex xs12>
          <template>
            <v-tabs v-model="tab" align-with-title>
              <v-tabs-slider color="orange lighten-4"></v-tabs-slider>
              <v-tab> 工作報告 / Scans Report </v-tab>
              <v-tab> 打印報告 / Prints Report </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item
                ><v-card flat>
                  <v-card-text><Report1 /></v-card-text> </v-card
              ></v-tab-item>
              <v-tab-item
                ><v-card flat>
                  <v-card-text>
                    <Report2 />
                  </v-card-text> </v-card
              ></v-tab-item>
            </v-tabs-items>
          </template>
        </v-flex>
      </v-layout>
    </v-container>
    <AFooter />
  </v-app>
</template>

<script>
import AHeader from "@/components/AHeader.vue";
import AFooter from "@/components/AFooter.vue";
import Report1 from "@/components/Report1.vue";
import Report2 from "@/components/Report2.vue";

export default {
  data() {
    return {
      tab: null,
    };
  },
  components: {
    AHeader,
    AFooter,
    Report1,
    Report2,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
