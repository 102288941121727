<template>
  <v-app>
    <AHeader />
    <v-container fluid class="text-center">
      <v-layout row wrap>
        <v-flex xs12>
          <v-data-table
            :headers="headers"
            :items="clients"
            :search="search"
            :items-per-page="10"
            item-key="id"
            sort-by="id"
            :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'mdi-arrow-collapse-left',
              lastIcon: 'mdi-arrow-collapse-right',
              prevIcon: 'mdi-minus',
              nextIcon: 'mdi-plus',
            }"
          >
            <template v-slot:top>
              <v-toolbar flat color="white">
                <v-toolbar-title>客戶管理 / Client Management</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  label="搜尋 / Search"
                  single-line
                  hide-details
                ></v-text-field>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-dialog v-model="dialog" max-width="500px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      tile
                      color="orange lighten-4"
                      v-bind="attrs"
                      v-on="on"
                    >
                      新增客戶 / New Client
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="headline">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="6">
                            <v-text-field
                              v-model="editedItem.last_name"
                              label="姓 / Last Name"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="editedItem.first_name"
                              label="名 / First Name"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field
                              v-model="editedItem.mobile"
                              label="電話 / Mobile"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field
                              v-model="editedItem.email"
                              label="電郵 / Email"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="6">
                            <v-text-field
                              v-model="editedItem.height"
                              label="身高 / Height"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="orange lighten-4" tile @click="close">
                        取消 / Cancel
                      </v-btn>
                      <v-btn color="orange lighten-4" tile @click="save">
                        保存 / Save
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-btn
                color="orange lighten-4"
                tile
                small
                @click="editItem(item)"
              >
                更改 / Edit
              </v-btn>
            </template>
            <template v-slot:no-data>
              <v-btn color="orange lighten-4" tile small @click="getClientList">
                重設 / Reload
              </v-btn>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
    </v-container>
    <AFooter />
  </v-app>
</template>

<script>
import AHeader from "@/components/AHeader.vue";
import AFooter from "@/components/AFooter.vue";
import { mapGetters } from "vuex";
import qs from "qs";

export default {
  data() {
    return {
      dialog: false,
      search: "",
      headers: [
        { text: "編號 / ID", align: "center", value: "id" },
        { text: "姓 / Last Name", align: "center", value: "last_name" },
        { text: "名 / First Name", align: "center", value: "first_name" },
        { text: "電話 / Mobile", align: "center", value: "mobile" },
        { text: "電郵 / Email", align: "center", value: "email" },
        { text: "身高 / Height", align: "center", value: "height" },
        {
          text: "動作 / Action",
          align: "center",
          value: "actions",
          sortable: false,
        },
      ],
      clients: [],
      editedIndex: -1,
      editedItem: {
        id: "",
        last_name: "",
        first_name: "",
        mobile: "",
        email: "",
        height: "",
      },
      defaultItem: {
        last_name: "",
        first_name: "",
        mobile: "",
        email: "",
        height: "",
      },
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "新增客戶 / New Client"
        : "更改客戶 / Edit Client";
    },
    ...mapGetters({
      StudioInfo: "studioinfo",
    }),
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  created() {
    this.getClientList();
  },
  methods: {
    async getClientList() {
      await this.axios
        .get(this.serverurl + "/getClients/" + this.StudioInfo.user.id, {
          headers: {
            Authorization: "Bearer " + this.StudioInfo.token,
          },
        })
        .then((response) => {
          //console.log(response.data);
          this.clients = response.data;
        })
        .catch((error) => {
          this.errors = error;
        });
    },
    async newClient() {
      await this.axios
        .post(
          this.serverurl + "/newClient",
          qs.stringify({
            first_name: this.editedItem.first_name,
            last_name: this.editedItem.last_name,
            mobile: this.editedItem.mobile,
            email: this.editedItem.email,
            height: this.editedItem.height,
            studio_id: this.StudioInfo.user.id,
          }),
          {
            headers: {
              Authorization: "Bearer " + this.StudioInfo.token,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          this.getClientList();
          this.clear();
        })
        .catch((error) => {
          this.errors = error;
        });
    },
    async updateClient() {
      await this.axios
        .post(
          this.serverurl + "/updateClient",
          qs.stringify({
            id: this.editedItem.id,
            first_name: this.editedItem.first_name,
            last_name: this.editedItem.last_name,
            mobile: this.editedItem.mobile,
            email: this.editedItem.email,
            height: this.editedItem.height,
            studio_id: this.StudioInfo.user.id,
          }),
          {
            headers: {
              Authorization: "Bearer " + this.StudioInfo.token,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          this.getClientList();
          this.clear();
        })
        .catch((error) => {
          this.errors = error;
        });
    },
    clear() {
      this.$refs.form.reset();
    },
    editItem(item) {
      this.editedIndex = this.clients.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      if (this.editedIndex > -1) {
        this.updateClient();
        Object.assign(this.clients[this.editedIndex], this.editedItem);
      } else {
        this.newClient();
        this.clients.push(this.editedItem);
      }
      this.close();
    },
  },
  components: {
    AHeader,
    AFooter,
  },
};
</script>
