<template>
  <v-app>
    <Header />
    <v-container fluid class="text-center">
      <h2>主目錄 / Main Menu</h2>
    </v-container>
    <v-container fluid class="text-center">
      <v-row>
        <v-col>
          <v-btn
            tile
            block
            height="80"
            color="orange lighten-4"
            to="/scheduler"
          >
            預約時間
            <br />Schedule a Job
          </v-btn>
        </v-col>
        <v-col>
          <v-btn tile block height="80" color="orange lighten-4" to="/jobs">
            工作管理
            <br />Job Management
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn tile block height="80" color="orange lighten-4" to="/clients">
            客戶管理
            <br />Client Management
          </v-btn>
        </v-col>
        <v-col>
          <v-btn
            tile
            block
            height="80"
            color="orange lighten-4"
            to="/orderlist"
          >
            訂單管理
            <br />Order Management
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn tile block height="80" color="orange lighten-4" to="/reports">
            報告
            <br />Reports
          </v-btn>
        </v-col>
        <v-col>
          <v-btn tile block height="80" color="orange lighten-4" to="/settings">
            設置
            <br />Setting
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <AFooter />
  </v-app>
</template>

<script>
import Header from "@/components/Header.vue";
import AFooter from "@/components/AFooter.vue";

export default {
  components: {
    Header,
    AFooter,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
