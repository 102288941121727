<template>
  <v-app>
    <AHeader />
    <v-container grid-list-md class="text-center">
      <v-layout row wrap>
        <v-flex xs12>
          <v-btn tile x-large color="orange lighten-4" to="jobs">
            反回 / Back
          </v-btn>
          <v-btn
            tile
            x-large
            color="orange lighten-4"
            @click="dialog_cart = true"
          >
            <v-badge right color="red darken-1">
              <template v-slot:badge>
                <span>{{ OrderCount }}</span>
              </template>
              貸單 / Orders
            </v-badge>
          </v-btn>
        </v-flex>
        <v-flex xs12>
          <v-layout v-for="scan in ScanList" :key="scan" row wrap>
            <v-flex v-for="index in 8" :key="index" md3 sm6 xs12>
              <img
                :src="'/static/tn_models/' + scan.id + '-' + index + '.jpg'"
                width="180"
                @error="$event.target.src = '/static/tn_models/nadefault.jpg'"
              />
              <br />
              {{ scan.id + "-" + index }}
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  tile
                  color="orange lighten-4"
                  @click="previewScan(scan.id + '-' + index)"
                  >3D</v-btn
                >
                <v-btn
                  tile
                  color="orange lighten-4"
                  @click.stop="curScan(scan.id + '-' + index)"
                >
                  購買 / Buy
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-layout row justify-center>
        <v-dialog v-model="dialog_cart" max-width="600">
          <v-card>
            <v-card-title class="text-center">
              <span>貸單 / Orders</span>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-btn color="orange lighten-4" tile to="/confirmorder">
                結帳 / checkout
              </v-btn>
              <v-btn color="orange lighten-4" tile @click="dialog_cart = false">
                關閉 / close
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="3">掃描編號 / scan id</v-col>
                <v-col cols="2">尺寸 / size</v-col>
                <v-col cols="2">數量 / quantity</v-col>
                <v-col cols="3">備註 / remark</v-col>
                <v-col cols="2">.</v-col>
              </v-row>
              <v-row v-for="item in PrintItems" :key="item.id" row wrap>
                <v-col cols="3">{{ item.scanid }}</v-col>
                <v-col cols="2">{{ item.size }}</v-col>
                <v-col cols="2">{{ item.qty }}</v-col>
                <v-col cols="3">{{ item.remark }}</v-col>
                <v-col cols="2">
                  <v-btn
                    tile
                    small
                    color="orange lighten-4"
                    @click="delPrint(item)"
                  >
                    刪除 / delete
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-layout>
      <v-layout row justify-center>
        <v-dialog v-model="dialog_order" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="headline">訂單詳情 / Order Detail</span>
            </v-card-title>
            <v-card-text>
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12>{{ currentScan.id }}</v-flex>
                  <v-flex xs12>
                    <v-select
                      :items="['1:10', '1:9', '1:8', '1:7', '1:6', '1:5']"
                      v-model="currentScan.size"
                      label="尺寸 / Size"
                      required
                    ></v-select>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      v-model="currentScan.qty"
                      label="數量 / Quantity"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      v-model="currentScan.remark"
                      label="備註 / Remark"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="orange lighten-4"
                tile
                @click="dialog_order = false"
              >
                關閉 / Close
              </v-btn>
              <v-btn color="orange lighten-4" tile @click="addtoPrint">
                加單 / Add
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
    </v-container>
    <AFooter />
  </v-app>
</template>

<script>
import AHeader from "@/components/AHeader.vue";
import AFooter from "@/components/AFooter.vue";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      dialog_cart: false,
      dialog_order: false,
      currentScan: {
        id: "",
        size: "",
        qty: "",
        remark: "",
      },
      headers: [
        {
          text: "尺寸 / Size",
          align: "center",
          sortable: false,
          value: "size",
        },
        {
          text: "數量 / Quantity",
          align: "center",
          value: "qty",
          sortable: false,
        },
        { text: "備註 / Remark", value: "remark", sortable: false },
        { text: "", align: "center", sortable: false },
      ],
      printorder: [],
      editedIndex: -1,
      editedItem: {
        name: "",
        qty: 0,
        remark: "",
      },
      defaultItem: {
        name: "",
        qty: 0,
        remark: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      PrintItems: "printitems",
      ScanList: "scanlist",
      OrderCount: "printcount",
    }),
  },
  methods: {
    previewScan(scanid) {
      this.$store.dispatch("set_Pscan", scanid);
      this.$router.push("preview");
    },
    curScan(scanid) {
      this.currentScan.id = scanid;
      this.dialog_order = true;
    },
    addtoPrint() {
      this.$store.dispatch("addPrint", this.currentScan);
      this.dialog_order = false;
    },
    delPrint(printitem) {
      this.$store.dispatch("deletePrint", printitem);
      //console.log(printitem);
    },
  },
  components: {
    AHeader,
    AFooter,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
