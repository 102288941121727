<template>
  <v-app>
    <AHeader />
    <v-container grid-list-md class="text-center">
      <v-alert :color="mtype" icon="warning_amber" v-if="mtype">
        {{ dataMsg }}
      </v-alert>
      <v-layout row wrap>
        <v-flex xs12>
          <v-card-title class="text-center">
            <span>訂單確認 / Order Confirmation</span>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-btn tile color="orange lighten-4" @click="submit(0)">
              保存 / Save
            </v-btn>
            <v-btn tile color="orange lighten-4" @click="submit(1)">
              確定 / Confirmed
            </v-btn>
          </v-card-title>
          <v-card-text>
            客戶編號 / Client ID: {{ ClientInfo.id }}
            <h2>{{ ClientInfo.last_name }}, {{ ClientInfo.first_name }}</h2>
          </v-card-text>
        </v-flex>
      </v-layout>
      <v-layout>
        <v-flex xs12>
          <v-row>
            <v-col cols="4">掃描编號 / Scan ID</v-col>
            <v-col cols="2">尺寸 / Size</v-col>
            <v-col cols="2">數量 / Quantity</v-col>
            <v-col cols="4">備註 / remark</v-col>
          </v-row>
          <v-row v-for="item in PrintItems" :key="item.id" row wrap>
            <v-col cols="4">{{ item.scanid }}</v-col>
            <v-col cols="2">{{ item.size }}</v-col>
            <v-col cols="2">{{ item.qty }}</v-col>
            <v-col cols="4">{{ item.remark }}</v-col>
          </v-row>
        </v-flex>
      </v-layout>
    </v-container>
    <AFooter />
  </v-app>
</template>

<script>
import AHeader from "@/components/AHeader.vue";
import AFooter from "@/components/AFooter.vue";
import { mapGetters } from "vuex";
import qs from "qs";

export default {
  data() {
    return {
      mtype: null,
      dataMsg: "",
    };
  },
  computed: {
    ...mapGetters({
      PrintItems: "printitems",
      ClientInfo: "clientinfo",
      StudioInfo: "studioinfo",
    }),
  },
  methods: {
    async submit(s) {
      await this.axios
        .post(
          this.serverurl + "/newOrder",
          qs.stringify({
            studio_id: this.StudioInfo.user.id,
            client_id: this.ClientInfo.id,
            items: this.PrintItems,
            status: s,
          }),
          {
            headers: {
              Authorization: "Bearer " + this.StudioInfo.token,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          this.$store.dispatch("bigReset");
          this.$router.push("jobs");
        })
        .catch((error) => {
          this.dataMsg = error;
          this.mtype = "warning";
        });
    },
  },
  components: {
    AHeader,
    AFooter,
  },
};
</script>
