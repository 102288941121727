<template>
  <v-app>
    <AHeader />
    <v-container fluid class="text-center">
      <h2>更改密碼 / Reset Password</h2>
    </v-container>
    <v-container fluid class="text-center">
      <v-alert :color="mtype" icon="warning_amber" v-if="mtype">
        {{ dataMsg }}
      </v-alert>
      <v-layout justify-center>
        <v-flex xs12 sm10 md8 lg6>
          <v-form ref="form">
            <v-card-text>
              <v-text-field
                v-model="currentPwd"
                label="現在密碼 / Current Password"
                type="password"
                required
              ></v-text-field>
              <v-text-field
                v-model="newPwd"
                label="新密碼 / Password"
                type="password"
                required
              ></v-text-field>
              <v-text-field
                v-model="newPwd2"
                label="確定密碼 / Confirmation Password"
                type="password"
                required
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-btn tile x-large color="orange lighten-4" to="/mainmenu">
                取消 / Cancel
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn tile x-large color="orange lighten-4" @click="submit">
                提交 / Submit
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-flex>
      </v-layout>
    </v-container>
    <AFooter />
  </v-app>
</template>

<script>
import AHeader from "@/components/AHeader.vue";
import AFooter from "@/components/AFooter.vue";
import { mapGetters } from "vuex";
import qs from "qs";

export default {
  data() {
    return {
      mtype: null,
      dataMsg: "",
      currentPwd: "",
      newPwd: "",
      newPwd2: "",
      passwordRules: [
        (v) => !!v || "Confirmation Password is required",
        (v) => v == this.newPwd2 || "Password must match",
      ],
    };
  },
  computed: {
    ...mapGetters({
      StudioInfo: "studioinfo",
    }),
  },
  methods: {
    async submit() {
      if (this.newPwd == this.newPwd2) {
        await this.axios
          .post(
            this.serverurl + "/updatePwd",
            qs.stringify({
              studio_id: this.StudioInfo.user.id,
              currentpwd: this.currentPwd,
              newpwd: this.newPwd,
            }),
            {
              headers: {
                Authorization: "Bearer " + this.StudioInfo.token,
              },
            }
          )
          .then((response) => {
            this.mtype = "success";
            this.dataMsg = response.data
            this.$router.push('settings')
          })
          .catch((error) => {
            this.mtype = "warning";
            this.dataMsg = error.data;
          });
      } else {
        this.mtype = "warning";
        this.dataMsg = "Password Don't Match";
      }
    },
    clear() {
      this.$refs.form.reset();
    },
  },
  components: {
    AHeader,
    AFooter,
  },
};
</script>
