var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.r1headers,"items":_vm.jobs,"search":_vm.search,"items-per-page":10,"item-key":"id","sort-by":"id","footer-props":{
    showFirstLastPage: true,
    firstIcon: 'mdi-arrow-collapse-left',
    lastIcon: 'mdi-arrow-collapse-right',
    prevIcon: 'mdi-minus',
    nextIcon: 'mdi-plus',
  }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-menu',{ref:"r1menu",attrs:{"close-on-content-click":false,"return-value":_vm.r1date,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},on:{"update:returnValue":function($event){_vm.r1date=$event},"update:return-value":function($event){_vm.r1date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"挑選月份 / Pick a month","readonly":""},model:{value:(_vm.r1date),callback:function ($$v) {_vm.r1date=$$v},expression:"r1date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.r1menu),callback:function ($$v) {_vm.r1menu=$$v},expression:"r1menu"}},[_c('v-date-picker',{attrs:{"type":"month","no-title":"","scrollable":""},model:{value:(_vm.r1date),callback:function ($$v) {_vm.r1date=$$v},expression:"r1date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.r1menu = false}}},[_vm._v(" 取消 / Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.getDoneScans(_vm.r1date)}}},[_vm._v(" 去 / OK ")])],1)],1),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-text-field',{attrs:{"label":"搜尋 / Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }