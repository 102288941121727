<template>
  <v-app>
    <AHeader />
    <v-container fluid class="text-center">
      <h2>設定 / Setting</h2>
    </v-container>
    <v-container fluid class="text-center">
      <v-btn
        tile
        block
        height="80"
        to="/changepassword"
        color="orange lighten-4"
      >
        更改密碼
        <br />Change Password
      </v-btn>
    </v-container>
    <AFooter />
  </v-app>
</template>

<script>
import AHeader from "@/components/AHeader.vue";
import AFooter from "@/components/AFooter.vue";

export default {
  components: {
    AHeader,
    AFooter,
  },
};
</script>
